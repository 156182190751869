.open-map-card {
  position: absolute;
  padding: 10px;
  font-family: Mitr;
  top: -230px;
  left: -250px;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .open-map-card {
    top: -230px;
    left: -205px;
  }  
}

@media (max-width: 991px) {
  .open-map-card {
    top: -230px;
    left: -145px;
  }  
}

@media (max-width: 767px) {
  .open-map-card {
    top: -230px;
    left: -235px;
  }  
}

@media (max-width: 575px) {
  .open-map-card {
    top: -230px;
    left: -150px;
  }  
}