.about-us .hero_single .wrapper {
  background-color: transparent;
}

.about-us .hero_single {
  background-size: cover;
  background-image: url('/public/img/bg/aboutus.JPG');
  opacity: 0.8;
}

.about-us .container {
  background-color: rgb(0, 0, 0, 0.8);
  color: #fff;
  padding: 50px 30px;
}

.about-us h3::after {
  content: '';
  border-bottom: 3px solid #385E1D;
  padding-top: 15px;
  width: 110px;
  display: block;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 1199px) {
  .en .hero_single .wrapper {
    height: 110%;
  }
}


@media (max-width: 768px) {
  .about-us .hero_single .wrapper {
    height: 110%;
  }
  .en .hero_single .wrapper {
    height: 110%;
  }
  .en h3 {
    font-size: 2rem!important;
  }
  .en p {
    font-size: 1.3rem!important;
  }
}

@media (max-width: 767px) {
  .about-us .hero_single {
    height: 100vh;
  }

  .about-us .container {
    margin: 0 10px;
    max-width: fit-content;
  }
}

@media (max-width: 576px) {
  .about-us .container {
    padding: 20px 10px;
  }
  .about-us .header {
    top: -54px;
    left: -13px;
  }
  .en .hero_single .wrapper {
    height: 107%;
  }
  .en h3 {
    font-size: 1.5rem!important;
  }
  .en p {
    font-size: 1rem!important;
  }
}

@media (max-width: 374px) {
  .about-us .hero_single {
    height: 120vh;
  }
  .about-us .hero_single .wrapper {
    height: 102%;
  }
}
