.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: white;
  width: 95%;
  text-align: center;
  z-index: 2;
  white-space: pre-line;
}

.image-cropper {
  position: relative;
  overflow: hidden;
  border-radius: 10%;
  background-color: black;
  cursor: pointer;
}

.image-dimmer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: #0000002d;
}

@media (max-width: 767px) {
  .image-dimmer {
    background-color: #0000002d;
  }
}

@media (max-width: 480px) {
  .slick-prev {
    left: -13px !important;
  }
  .slick-next {
    right: -13px !important;
  }
}

.image-dimmer:hover {
  background-color: transparent;
}

.centered:hover + .image-dimmer {
  background-color: transparent;
}

.slick-prev:before,
.slick-next:before {
  color: #385e1d !important;
}
