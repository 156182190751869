.home-page .hero_single .wrapper {
  background-color: transparent;
}

header.sticky {
  background: rgb(0, 0, 0, 0.4) !important;
}

.home-page .hero_single {
  background-size: cover;
  animation: bgfade 15s infinite;
}

@keyframes bgfade {
  0% {
    background-image: url('/public/img/homepages/1.jpg');
  }
  25% {
    background-image: url('/public/img/homepages/1.jpg');
  }
  50% {
    background-image: url('/public/img/homepages/2.jpg');
  }
  75% {
    background-image: url('/public/img/homepages/3.jpg');
  }
  100% {
    background-image: url('/public/img/homepages/4.jpg');
  }
}